import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "@app/store/store";
import { City } from "@shared/lib/interfaces/city.interface";
import LocationIcon from "../../../Svg/LocationIcon";
import { LocationButtonProps } from "./types";
import classes from "./LocationButton.module.scss";

const LocationButton = ({ onClick, isConfirmShown }: LocationButtonProps) => {
    const savedCities = useSelector((state: AppState) => state.filters.city);
    let city: City | undefined = savedCities[0];

    const [storageCity, setStorageCity] = useState<City | null>(null);
    const labelCity = isConfirmShown
        ? storageCity?.name || "Все города"
        : city?.name || "Все города";

    const handleButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
        onClick(e);
    };

    useEffect(() => {
        const localCity = localStorage.getItem("city");

        try {
            const parsedCity = JSON.parse(localCity || "");
            if (typeof parsedCity === "string") {
                localStorage.removeItem("city");
            } else {
                setStorageCity(parsedCity);
            }
        } catch (error) {
            localStorage.removeItem("city");
        }
    }, []);

    return (
        <div onClick={handleButtonClick} className={classes.LocationButton}>
            <LocationIcon />
            <label>{labelCity}</label>
        </div>
    );
};
export default LocationButton;