import { CURRENT_YEAR, START_YEAR_PRODUCTION } from "@shared/config/Date";
import { generateYearsProduction } from "@shared/lib/utils/generateYearsProduction";

export const YEARS_PRODUCTION = generateYearsProduction(START_YEAR_PRODUCTION, CURRENT_YEAR);

export const FILTER_LABELS = {
    BRAND: "Марка авто",
    MODEL: "Модель",
    GENERATION: "Поколение",
    YEAR_FROM: "Год от",
    YEAR_TO: "до",
    MILEAGE_FROM: "Пробег от",
    MILEAGE_TO: "до",
    PRICE_FROM: "Цена от",
    PRICE_TO: "до",
    PLATFORM: "Доска объявлений",
    BODY: "Кузов",
    TRANSMISSION: "Трансмиссия",
    ANY: "Любой",
    ANY_FEMALE: "Любая",
    ENGINE_TYPE: "Двигатель",
    DRIVE: "Привод",
    ENGINE_VOLUME: "Объем двигателя",
    ENGINE_VOLUME_FROM: "От",
    ENGINE_VOLUME_TO: "До",
    HORSE_POWER: "Мощность двигателя (л.с.)",
    HORSE_POWER_FROM: "От",
    HORSE_POWER_TO: "До",
    WHEEL: "Руль",
    OWNERS_QNT: "Количество владельцев",
    OWNERS_QNT_FROM: "От",
    OWNERS_QNT_TO: "До",
    SELLER_TYPE: "Тип продавца",
    SOURCE: "Доска объявлений",
    COUNTRY: "В какой стране продается",
    SEARCH_MAP: "Поиск на карте",
    RESET_FILTERS: "Сбросить",
    COMPARE: "Сравнить",
    HIDE_NO_IMAGES: "Не показывать объявления без фото"
};

export const BREAKPOINT_EXPANDED = 768;