import React from "react";
import { GENERATION_FOLDER } from "@components/UI/Inputs/IconWithLabel/constants";
import { IconWithLabel } from "../IconWithLabel/IconWithLabel";
import { GenerationsInputOptionProps } from "./GenerationsInputOption.props";
import classes from "./GenerationsInputOption.module.scss";

const GenerationsInputOption: React.FC<GenerationsInputOptionProps> = ({ data, ...props }) => {
    const genUriId = `${data.modelValue?.id}10${data.restyleNumber || 0}`;
    return (
        <div className={classes.Cont} {...props.innerProps}>
            <IconWithLabel
                option={{
                    ...data,
                    value: genUriId
                }}
                folder={GENERATION_FOLDER}
            />
        </div>
    );
};

export default GenerationsInputOption;