import React from "react";
import classNames from "classnames";
import { CheckboxProps } from "./types";
import classes from "./Checkbox.module.scss";

export const Checkbox = ({ className, checked, onChange, label, id }: CheckboxProps) => {
    return (
        <div className={classNames(classes["checkbox"], className)}>
            <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={onChange}
                className={classes["checkbox__input"]}
                aria-checked={checked}
                aria-labelledby={`${id}-label`}
            />
            <label htmlFor={id} className={classes["checkbox__label"]} id={`${id}-label`}>
                <span className={classes["checkbox__box"]}>
                    {checked && (
                        <span
                            className={classes["checkbox__checkmark"]}
                            data-testid="checkbox-checkmark"
                        />
                    )}
                </span>
                {label}
            </label>
        </div>
    );
};