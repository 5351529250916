import React from "react";
import { components as SelectComponents, GroupProps } from "react-select";
import { CustomComponentProps } from "./types";

export const CustomLoadingMessage: React.FC<CustomComponentProps> = ({ className }) => (
    <div className={className}>Загрузка...</div>
);

export const CustomNoOptionsMessage: React.FC<CustomComponentProps> = ({ className }) => (
    <div className={className}>Нет опций</div>
);

export const Group: React.FC<GroupProps & CustomComponentProps> = ({ className, ...props }) => (
    <div className={className}>
        <SelectComponents.Group {...props} />
    </div>
);